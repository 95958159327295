<template>
  <section class="flex-fill mt-3">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <b-card
            header="Login"
            header-class="bg-white main-color text-uppercase font-weight-bold"
          >
            <b-card-text class="lead"
              >Sono già stato vostro cliente</b-card-text
            >

            <form v-on:submit.prevent="onSubmit">
              <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input
                  v-model="email"
                  id="email"
                  type="email"
                  name="email"
                  class="form-control"
                  autocomplete="email"
                />
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input
                  v-model="password"
                  id="password"
                  type="password"
                  name="password"
                  class="form-control"
                  autocomplete="current-password"
                />
              </div>
              <div class="form-group">
                <b-button @click="login">
                  <font-awesome-icon icon="sign-in-alt" /> Log in
                </b-button>
                <div class="mt-4">
                  <b-alert v-if="error" show fade variant="danger">
                    {{ error }}
                  </b-alert>
                </div>
              </div>
            </form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      apiUrl: process.env.VUE_APP_API_ENDPOINT,
      error: null
    };
  },
  methods: {
    login: function() {
      this.$http
        .post(this.apiUrl + "m_login", {
          email: this.email,
          password: this.password
        })
        .then(res => {
          if (res.data.token) {
            this.$store.commit("SET_JWT", res.data.token);
            window.previousUrl
              ? this.$router.push(window.previousUrl)
              : this.$router.push("/");
          }
        })
        .catch(err => {
          this.error = err.response.data;
        });
    }
  }
};
</script>
