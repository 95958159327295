<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid v-if="cartItemsList">
      <template v-if="!order">
        <b-row class="py-4">
          <b-col cols="9">
            <b-form-input
              type="text"
              @keyup.enter="add"
              v-model="barcode"
              ref="barcodeInput"
              autofocus
          /></b-col>
          <b-col cols="3" class="fa-pull-right">
            <b-form-checkbox switch v-model="showExtraDiscount"
              >Mostra extra sconto</b-form-checkbox
            ></b-col
          >
        </b-row>
        <b-row class="py-4">
          <template v-if="cartItemsList === null">
            <b-col class="text-center">
              <b-spinner style="width: 6rem; height: 6rem;"></b-spinner>
            </b-col>
          </template>
          <template v-else-if="cartItemsList.length === 0">
            <b-col class="text-center">
              carrello vuoto
            </b-col>
          </template>
          <template v-if="cartItemsList !== null && cartItemsList.length > 0">
            <b-col lg="3">
              <b-card
                class="sticky-top sticky-offset-filter"
                style="top: 60px;"
              >
                <b-card-sub-title class="mb-3">
                  Totale ({{ getCartItemsTotalQuantity() }} articolo/i):
                </b-card-sub-title>
                <b-card-text class="text-right">
                  <h4>
                    <strong
                      ><price :amount="getCartTotalAmount()"></price
                    ></strong>
                  </h4>
                </b-card-text>
                <b-button block size="lg" @click="checkout()"
                  >Checkout</b-button
                >
              </b-card>
            </b-col>
            <b-col lg="9" order-lg="first" class="mb-4 mb-lg-0">
              <div
                class="d-none d-md-block bg-white main-color w-100 p-3 border-bottom"
              >
                <b-row>
                  <b-col cols="8">
                    <span><strong>Prodotto</strong></span>
                  </b-col>
                  <b-col cols="2" class="d-flex justify-content-center">
                    <span><strong>Quantità</strong></span>
                  </b-col>
                  <b-col cols="2" class="d-flex justify-content-center">
                    <span><strong>Prezzo</strong></span>
                  </b-col>
                </b-row>
              </div>
              <div class="p-0" v-for="(item, key) in cartItemsList" :key="key">
                <b-row class="d-flex text-muted align-items-center mt-3">
                  <b-col md="8">
                    <b-row>
                      <b-col
                        cols="6"
                        class="mb-2 mb-md-0 d-flex justify-content-start"
                      >
                        <b-link
                          :href="
                            getItemUrl(
                              item.itemId,
                              item.brand + ' ' + item.code + ' ' + item.name
                            )
                          "
                          target="_blank"
                        >
                          <b-img
                            class="image-cart"
                            :src="thumbImagePath(item.thumbImagePath)"
                            :alt="
                              item.brand + ' ' + item.code + ' ' + item.name
                            "
                          />
                        </b-link>
                      </b-col>
                      <b-col cols="6">
                        <b-row
                          class="mt-1 d-flex align-items-center justify-content-start"
                        >
                          <b-link
                            :href="
                              getItemUrl(
                                item.itemId,
                                item.brand + ' ' + item.code + ' ' + item.name
                              )
                            "
                            target="_blank"
                          >
                            <strong class="text-dark"
                              >{{ item.brand }} {{ item.code }}</strong
                            >
                          </b-link>
                        </b-row>
                        <b-row
                          class="mt-1 mt-lg-3 pr-2 d-flex align-items-center justify-content-start"
                        >
                          <strong style="text-align: justify;">{{
                            item.name
                          }}</strong>
                        </b-row>
                        <b-row
                          class="mt-1 mt-lg-3 d-flex align-items-center justify-content-start"
                        >
                          <AvailabilityText
                            :type="item.availability"
                          ></AvailabilityText>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="6"
                    md="2"
                    class="d-flex justify-content-center border-0 mt-3 mt-md-0"
                  >
                    <b-input-group size="sm">
                      <b-input-group-prepend>
                        <b-button
                          class="bg-white border"
                          @click="addItemToCart(item.itemId, -1)"
                        >
                          <font-awesome-icon
                            class="main-color"
                            :icon="item.quantity > 1 ? 'minus' : 'trash'"
                            size="lg"
                          />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                        type="text"
                        class="border text-center font-weight-bold"
                        :value="item.quantity"
                        aria-label="Quantity"
                        disabled
                      />
                      <b-input-group-append>
                        <b-button
                          class="bg-white border"
                          @click="addItemToCart(item.itemId)"
                        >
                          <font-awesome-icon
                            class="main-color"
                            icon="plus"
                            size="lg"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col
                    cols="6"
                    md="2"
                    class="justify-content-center border-0 mt-3 mt-md-0"
                  >
                    <b-row>
                      <p v-if="item.listPrice > item.sellPrice">
                        <strong class="text-muted"
                          ><s><price :amount="item.listPrice"/></s></strong
                        ><b-badge
                          v-if="item.discount"
                          class="text-white ml-2"
                          :class="{
                            'badge-danger': item.discountThruDate,
                            'main-background': !item.discountThruDate
                          }"
                          style="font-size: 9pt; height: 17px"
                          >{{ item.discount }} %</b-badge
                        >
                      </p>
                    </b-row>
                    <b-row>
                      <strong class="text-dark">
                        <price :amount="item.sellPrice"></price>
                      </strong>
                    </b-row>
                  </b-col>
                </b-row>
                <b-col class="p-0 py-2 border-top-0 border-bottom">
                  <div class="d-flex">
                    <div class="flex-fill"></div>
                    <b-button
                      variant="link"
                      @click="deleteCartItem(item.itemId)"
                    >
                      <font-awesome-icon class="text-muted" icon="trash" />
                    </b-button>
                  </div>
                </b-col>
              </div>
              <div class="d-flex mt-3">
                <b-col class="px-0">
                  <div class="d-flex">
                    <div class="flex-fill d-flex justify-content-end mr-2">
                      <b-button size="sm" @click="emptyCart">
                        <font-awesome-icon class="mr-1" icon="times" />
                        Svuota il carrello
                      </b-button>
                    </div>
                    <div>
                      <b-button size="sm" @click="setCartItemsList">
                        <font-awesome-icon class="mr-1" icon="sync" />
                        Aggiorna il carrello
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </div>
            </b-col>
          </template>
        </b-row>
      </template>
      <template v-else>
        <span ref="print">
          <b-row class="py-4">
            <b-col class="main-background">
              <img
                alt="Tecnomodel Logo"
                src="@/assets/tecnomodel_white_logo.png"
            /></b-col>
          </b-row>
          <b-row class="py-4">
            <template>
              <b-col lg="3">
                <b-card
                  class="sticky-top sticky-offset-filter"
                  style="top: 60px;"
                >
                  <b-card-sub-title class="mb-3">
                    Totale ({{ getCartItemsTotalQuantity() }} articolo/i):
                  </b-card-sub-title>
                  <b-card-text class="text-right">
                    <h5 class="text-muted">
                      <price
                        strike
                        :amount="getCartPriceListTotalAmount()"
                      ></price>
                    </h5>
                    <h4>
                      <strong
                        ><price :amount="getCartTotalAmount()"></price
                      ></strong>
                    </h4>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col lg="9" order-lg="first" class="mb-4 mb-lg-0">
                <div
                  class="d-none d-md-block bg-white main-color w-100 p-3 border-top border-bottom"
                >
                  <b-row>
                    <b-col cols="4">
                      <span><strong>Prodotto</strong></span>
                    </b-col>
                    <b-col cols="4">
                      <span><strong>Descrizione</strong></span>
                    </b-col>
                    <b-col cols="2" class="d-flex justify-content-center">
                      <span><strong>Quantità</strong></span>
                    </b-col>
                    <b-col cols="2" class="d-flex justify-content-center">
                      <span><strong>Subtotale</strong></span>
                    </b-col>
                  </b-row>
                </div>
                <div
                  class="p-0"
                  v-for="(item, key) in cartItemsList"
                  :key="key"
                >
                  <b-row class="d-flex text-muted align-items-center mt-3">
                    <b-col md="8">
                      <b-row>
                        <b-col
                          cols="6"
                          class="mb-2 mb-md-0 d-flex justify-content-start"
                        >
                          <b-link
                            :to="{
                              name: 'item',
                              params: {
                                itemId: item.itemId
                              }
                            }"
                          >
                            <b-img
                              style="height: 8vh; object-fit: contain;"
                              :src="thumbImagePath(item.thumbImagePath)"
                              :alt="
                                item.brand + ' ' + item.code + ' ' + item.name
                              "
                            />
                          </b-link>
                        </b-col>
                        <b-col cols="6">
                          <b-row
                            class="mt-1 d-flex align-items-center justify-content-start"
                          >
                            <b-link
                              :to="{
                                name: 'item',
                                params: {
                                  itemId: item.itemId
                                }
                              }"
                            >
                              <strong class="text-dark"
                                >{{ item.brand }} {{ item.code }}</strong
                              >
                            </b-link>
                          </b-row>
                          <b-row
                            class="mt-1 mt-lg-3 pr-2 d-flex align-items-center justify-content-start"
                          >
                            <strong style="text-align: justify;">{{
                              item.name
                            }}</strong>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      cols="6"
                      md="2"
                      class="d-flex justify-content-center border-0 mt-3 mt-md-0"
                    >
                      {{ item.quantity }}
                    </b-col>
                    <b-col
                      cols="6"
                      md="2"
                      class="justify-content-center border-0 mt-3 mt-md-0"
                    >
                      <b-row>
                        <p v-if="item.listPrice > item.sellPrice">
                          <strong class="text-muted"
                            ><s><price :amount="item.listPrice"/></s></strong
                          ><b-badge
                            v-if="item.discount"
                            class="text-white ml-2"
                            :class="{
                              'badge-danger': item.discountThruDate,
                              'main-background': !item.discountThruDate
                            }"
                            style="font-size: 9pt; height: 17px"
                            >{{ item.discount }} %</b-badge
                          >
                        </p>
                      </b-row>
                      <b-row>
                        <strong class="text-dark">
                          <price :amount="item.sellPrice"></price>
                        </strong>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-col class="p-0 py-2 border-top-0 border-bottom"> </b-col>
                </div>
                <div class="d-flex mt-3">
                  <b-col class="px-0">
                    <div class="d-flex">
                      <div>
                        <b-button size="sm" @click="print">
                          <font-awesome-icon class="mr-1" icon="print" />
                          Stampa
                        </b-button>
                      </div>
                      <div class="flex-fill d-flex justify-content-end mr-2">
                        <b-button size="sm" @click="reset">
                          <font-awesome-icon class="mr-1" icon="sync" />
                          Nuovo acquisto
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </div>
              </b-col>
            </template>
          </b-row>
        </span>
      </template>
    </b-container>
  </section>
</template>
<script>
import AvailabilityText from "@/components/AvailabilityText";
import Price from "@/components/Price";
import { urlMixin } from "../mixins/urlMixin";

export default {
  name: "Cart",
  data() {
    return {
      barcode: "",
      cartItemsList: null,
      order: null,
      showExtraDiscount: false
    };
  },
  mixins: [urlMixin],
  beforeMount: function() {
    this.updateCart();
    this.setCartItemsList();
  },
  components: { Price, AvailabilityText },
  computed: {
    spinner: function() {
      return this.cartItemsList === null;
    }
  },
  watch: {
    showExtraDiscount: function() {
      this.setCartItemsList();
    }
  },
  mounted: function() {
    if (this.cartItemsList === null) this.setCartItemsList();
  },
  methods: {
    getCartItemsTotalQuantity: function() {
      if (!this.cartItemsList) return 0;
      let itemCount = 0;
      for (let item of this.cartItemsList) {
        itemCount += parseInt(item.quantity);
      }
      return itemCount;
    },
    getCartTotalAmount: function() {
      let amount = 0;
      for (let item of this.cartItemsList) {
        amount += item.quantity * item.sellPrice;
      }
      return amount;
    },
    getCartPriceListTotalAmount: function() {
      let amount = 0;
      for (let item of this.cartItemsList) {
        amount += item.quantity * item.listPrice;
      }
      return amount;
    },
    add: function() {
      this.addItemToCart(this.barcode);
    },
    addItemToCart: function(barcode, quantity = 1) {
      let data = {
        quantity: quantity
      };
      let key = "itemId";
      if (this.barcode) {
        this.barcode = "";
        key = "barcode";
      }
      data[key] = barcode;
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}cart`, data)
        .then(res => {
          if (res.data.success) {
            this.setCartItemsList();
          }
        })
        .catch(err => {
          this.error = err;
        });
    },
    thumbImagePath: function(path) {
      return path ? path : process.env.VUE_APP_IMAGE_NOT_AVAILABLE;
    },
    deleteCartItem: function(itemId) {
      this.$http
        .delete(`${process.env.VUE_APP_API_ENDPOINT}cart/${itemId}`)
        .then(res => {
          if (res.data.success) this.setCartItemsList();
        })
        .catch(err => {
          this.error = err;
        });
    },
    updateCart: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}cart/update`)
        .then(res => {
          if (res.data.success) this.setCartItemsList();
        })
        .catch(err => {
          this.error = err;
        });
    },
    emptyCart: function() {
      this.$http
        .delete(`${process.env.VUE_APP_API_ENDPOINT}cart`)
        .then(res => {
          if (res.data.success) this.setCartItemsList();
        })
        .catch(err => {
          this.error = err;
        });
    },
    setCartItemsList: function() {
      this.focusBarcodeInput();
      let endpoint = `${process.env.VUE_APP_API_ENDPOINT}cart`;
      if (!this.showExtraDiscount) endpoint += "?extraDiscount=false";
      return this.$http
        .get(endpoint)
        .then(res => {
          this.cartItemsList = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    checkout: function() {
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}shop/checkout`)
        .then(res => {
          if (res.data.success) this.showOrder(res.data.orderId);
        })
        .catch(err => {
          this.error = err;
        });
    },
    showOrder: function(orderId) {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}shop/${orderId}`)
        .then(res => {
          this.order = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    reset: function() {
      this.order = null;
      this.cartItemsList = null;
    },
    print: function() {
      let htmlToPrint = this.$refs.print.innerHTML;

      console.log(htmlToPrint);

      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML;
      }

      let WindowPrint = window.open(
        "http://localhost:8080",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WindowPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
${stylesHtml}
  </head>
  <body>
    ${htmlToPrint}
  </body>
</html>`);

      WindowPrint.document.close();
      WindowPrint.focus();
      WindowPrint.print();
      WindowPrint.close();
    },
    focusBarcodeInput: function() {
      if (this.$refs.barcodeInput) this.$refs.barcodeInput.focus();
    },
    getItemUrl: function(itemId, name) {
      return (
        process.env.VUE_APP_API_PUBLIC_FRONT_END +
        "i/" +
        this.toSeoUrl(name) +
        "/" +
        itemId
      );
    }
  }
};
</script>

<style>
.image-cart {
  height: 15vh;
  object-fit: contain;
  max-width: 200px;
}
</style>
