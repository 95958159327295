<template>
  <span :class="{ strike: strike, 'font-weight-bold': bold }"
    >{{ getFormattedCurrency() }}
    <span
      class="text-success font-weight-bold"
      v-if="amount === 0 && showTextAtZero"
    >
      {{ text }}
    </span>
  </span>
</template>

<script>
export default {
  name: "Price",
  props: {
    amount: [Number, String],
    hideZero: {
      type: Boolean,
      default: false
    },
    showTextAtZero: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "GRATIS"
    },
    strike: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lang: null,
      currency: null
    };
  },
  beforeMount: function() {
    this.lang = navigator.language || navigator.userLanguage;
    this.currency = "EUR";
  },
  methods: {
    getFormattedCurrency: function() {
      if (this.amount === null) return "";
      let amount = this.amount;
      if (typeof amount === "string" || amount instanceof String)
        amount = parseFloat(amount);
      if (amount === 0 && this.hideZero) return "";
      return amount.toLocaleString(this.lang, {
        style: "currency",
        currency: this.currency,
        currencyDisplay: "symbol"
      });
    }
  }
};
</script>

<style>
.strike {
  text-decoration: line-through;
}
</style>
